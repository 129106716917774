<template>
    <div>
        <v-container class="kurdish text-center align-content-center align-center d-flex"
            style="height: 98vh;background:#F1F3F8">
            <v-progress-circular class="mx-auto" color="primary" :indeterminate="true" v-if="loading">
            </v-progress-circular>
            <v-layout row wrap justify-space-around v-else>
                <v-flex xs12 lg4 xl4>
                    <v-container>
                        <v-layout row wrap class="mt-4">
                            <v-flex xs12>
                                <v-img width="130px" class="mx-auto" height="130px" style="border-radius:50%"
                                    :src="user.user_profile_picture"></v-img>
                                <h3>{{user.user_display_name.toUpperCase()}} </h3>
                                A{{user.user_id}}
                            </v-flex>
                            <v-flex xs12>
                                <v-card flat v-if="giveaway && give_away_view" color="transparent">
                                    <v-card-title primary-title>
                                        {{giveaway.giveaway_title}}
                                    </v-card-title>
                                    <v-card-subtitle class="text-start">
                                        {{giveaway.giveaway_sub_title}}
                                    </v-card-subtitle>
                                    <v-card-text class="text-start">
                                        <vue-scratchable getPercentageCleared :brushOptions="brush"
                                            @percentageUpdate="updatePoints" :hideOptions="hide">
                                            <div class="wrapper">
                                                <br>
                                                <div v-if="winner == user.user_id" style="color:black">

                                                    <br>
                                                    <h2>
                                                        {{giveaway.giveaway_win_title}}
                                                    </h2>
                                                    <br>
                                                    <p>
                                                        {{giveaway.giveaway_win_sub_title}}
                                                    </p>
                                                </div>
                                                <div v-else style="color:black">
                                                    <br>
                                                    <h2>
                                                        {{giveaway.giveaway_lose_title}}
                                                    </h2>
                                                    <br>
                                                    <p>
                                                        {{giveaway.giveaway_lose_sub_title}}
                                                    </p>
                                                    <br>

                                                </div>
                                                <br>
                                                <br>
                                            </div>
                                        </vue-scratchable>
                                    </v-card-text>
                                </v-card>
                                <v-card color="transparent"
                                    v-else-if="giveaway && !giveaway.user_entered && !give_away_view" flat>
                                    <v-card-title primary-title>
                                        {{giveaway.giveaway_title}}
                                    </v-card-title>
                                    <v-card-subtitle class="text-start">
                                        {{giveaway.giveaway_sub_title}}
                                    </v-card-subtitle>
                                    <v-card-text class="text-start">
                                        <br>
                                        <h1>هاتووی؟</h1>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn rounded :loading="entry_loading" x-large block color="primary"
                                            @click="enter">
                                            هاتووم
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                                <v-card color="transparent"
                                    v-else-if="giveaway && giveaway.user_entered && !give_away_view" flat>
                                    <v-card-text class="text-start">

                                        <div v-if="winner == user.user_id" style="color:black">
                                            <br>
                                            <h2>
                                                {{giveaway.giveaway_win_title}}
                                            </h2>
                                            <br>
                                            <p>
                                                {{giveaway.giveaway_win_sub_title}}
                                            </p>
                                        </div>
                                        <div v-else style="color:black">
                                            <h1 primary-title>
                                                {{giveaway.giveaway_title}}
                                            </h1>
                                            <br>
                                            جارێ بۆ کەس دەرنەچووە 🔒
                                            <br>
                                            <br>
                                            <br>
                                            <h2>
                                                {{giveaway.giveaway_lose_title}}
                                            </h2>
                                            <br>
                                            <p>
                                                {{giveaway.giveaway_lose_sub_title}}
                                            </p>
                                            <br>

                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card v-else flat color="transparent">
                                    <h2 class="text-center mt-5">
                                        دەعوەت و شتمان نیە
                                    </h2>
                                </v-card>
                            </v-flex>
                            <v-flex xs12>
                                <v-card outlined class="text-start mx-4" color="primary" dark @click="info_dialog = true">
                                    <v-card-title primary-title>
                                        بۆچی بەرنامەی شایەن؟
                                    </v-card-title>
                                    <v-card-subtitle>
                                        دەسم پیانێ
                                    </v-card-subtitle>
                                </v-card>
                            </v-flex>
                        </v-layout>

                    </v-container>
                </v-flex>
            </v-layout>
        </v-container>
        <v-bottom-sheet transition="slide-y-reverse-transition"  v-model="info_dialog">
            <v-card style="border-top-left-radius: 15px;border-top-right-radius: 15px;" class="kurdish">
                <v-card-title primary-title>
                    بۆچی بەرنامەی شایەن؟
                    <v-spacer></v-spacer>
                    <v-btn color="error" icon @click="info_dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="black--text">
                    بەرنامەی شایەن تایبەت بە هەڵسەنگاندی ئەو شوێنانەی کە ڕۆژانە ئێمە بەرکەوتنمان هەیە لەگەڵیان وپێویستیەکانمان پڕئەکەنەوە، ئامانج لێی باشترکردنی خزمەتگوزاریەکان لەڕێگای پێدانی
                 خاڵ و تێبینی و سەرنجی شایستە بە شوێنەکە،
                 <br>
                 <span class="primary--text" style="font-size:16px">ڕۆڵی تۆی بەکارهێنەر</span>
                 بریتیە لە جوان مامەڵەکردن لەگەڵ شوێنە خزمەتگوزاریەکان و بەرنامەی شایەن بۆ باشترکردن و بەرەوپێشچوونی بازاڕگەری و سێرڤس لەناوچەکە، 
                 بۆیە هیوادارین لە پێدانی ڕەیت و ڕیڤیوو یان نوسینی کۆمێنت بۆ شوێنەکان بەوپەڕی ویژدان و بەرپسیارێتیەوە ڕەیت و ڕیڤیو بەن وە ئەگەر کەموکورتیان هەبوو ڕەخنەی خۆتان بگرن بۆ ئەوەی باشتر ببن بەزمانێکی شیرینی وەک شایەن نەک بۆ ئەوەی شوێنەکە ناو زڕاو بکەن و بیانشکێنن، 
                 <br>
                 دوبارە <span class="primary--text">دەست خۆش گەورەم گیان ❣️</span>
                 بۆ بەکارهێنانی شایەن و هاوکاریت بۆ  باشترکردنی  خزمەتگوزاری تایبەت بە کڕیار
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>
<script>
    import controller from '../controller/giveaway'
    export default {
        data() {
            return {
                user_id: '',
                info_dialog: false,
                loading: true,
                entry_loading: false,
                percentage: 0,
                give_away_view: false,
                winner: 1,
                user: {},
                giveaway: {},
                hide: {
                    type: 'color',
                    value: '#808080',
                },
                brush: {
                    size: 60,
                    shape: 'round',
                },
            }
        },
        mounted() {
            this.$vuetify.rtl = true
            this.user_id = this.$route.params.user_id
            this.loading = false
            this.getUser()
        },
        methods: {
            updatePoints(percentage) {
                this.percentage = percentage;
            },
            enter() {
                this.entry_loading = true
                controller.enter({
                        user_id: this.user_id,
                        giveaway_id: this.giveaway.giveaway_id
                    }).then(res => {
                        if (res.data.status == 'ok') {
                            this.give_away_view = true
                            this.entry_loading = false
                            this.getUser()
                        } else {
                            this.entry_loading = false
                            console.log(res);


                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getUser() {
                this.loading = true
                controller.give({
                    user_id: this.user_id,
                    today: new Date().toISOString().split('T')[0],
                }).then(res => {
                    this.user = res.data.user
                    this.giveaway = res.data.giveaway ? res.data.giveaway : null
                    if (res.data.giveaway) {
                        this.winner = res.data.giveaway.winner
                        // this.give_away_view = res.data.giveaway.user_entered
                    }
                    // setTimeout(() => {
                    //     this.loading = false
                    // }, 1000)
                    this.loading = false
                })
            }
        },
    }
</script>
<style>
    .wrapper {
        width: 100%;
        height: auto;
    }

    canvas {
        width: 100%;
        height: auto;
    }
</style>