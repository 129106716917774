
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_giveaway(params)  {
            return await axios.post('giveaway/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_items()  {
            return await axios.get('giveaway/read.php')
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async delete_items(giveaway_id)  {
            return await axios.post('giveaway/delete.php' , {
                giveaway_id : giveaway_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(giveaway_id)  {
            return await axios.post('giveaway/readOne.php' , {
                giveaway_id : giveaway_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_items(params)  {
            return await axios.post('giveaway/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_items(params)  {
            return await axios.post('giveaway/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async enter(params)  {
            return await axios.post('giveaway/enter.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async give(params)  {
            return await axios.post('giveaway/give.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
